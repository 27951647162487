import CampaignList from '../../../components/campaign/list/campaignList';

export default {
  name: 'CustomChannelCampaignList',
  data() {
    return {
      campaignChannelType: 'custom',
      campaignChannel: 'Custom Channel'
    };
  },
  components: {
    CampaignList
  },
  methods: {},
  mounted() {}
};
